<template>
	<Header />
	<div id="app">
		<div class="main-wrapper">
			<main class="main">
				<slot />
			</main>
		</div>
		<Footer />
	</div>
	<ClientOnly>
		<CookieNotification />
	</ClientOnly>
</template>
